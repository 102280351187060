<div mat-dialog-title *ngIf="dialogRef">
  <button
    *ngIf="!error?.overlayConfig?.hideCloseButton"
    id="close-btn"
    mat-icon-button
    mat-dialog-close
    class="close-btn"
    [title]="'ARIA_LABEL.DIALOG.CLOSE' | translate"
    [attr.aria-label]="'ARIA_LABEL.DIALOG.CLOSE' | translate"
  >
    <mat-icon>close_outlined</mat-icon>
  </button>
</div>

<div class="content_main-container text-center">
  <ng-container *ngIf="error.image; else showIcon">
    <img id="main_error_icon" class="error_img" [src]="error.image | translate" alt="" />
  </ng-container>

  <ng-template #showIcon>
    <mat-icon id="main_error_icon" class="icon-xlg mt-11 mb-9">{{ error.icon || 'report_problem_outlined' }}</mat-icon>
  </ng-template>
  <h1 id="main_error_heading" *ngIf="error.heading">{{ error.heading | translate }}</h1>
  <h2 id="main_error_title">{{ error.title | translate }}</h2>
  <div id="main_error_message" class="mat-body-2 mt-4 error-message" [innerHTML]="error.message | translate"></div>
  <div *ngIf="error.buttons && error.buttons?.length" class="content_main-button-list mt-40">
    <button
      mat-flat-button
      *ngFor="let button of error.buttons; let i = index"
      [color]="button.color"
      [id]="'error-button-' + i"
      (click)="onButtonClick(button)"
    >
      {{ button.label | translate }}
    </button>
  </div>
</div>
