import { ChangeDetectionStrategy, Component, Inject, OnInit, Optional } from '@angular/core';
import { ErrorButton, PbbError } from '@store/core/core.interface';
import { ActivatedRoute, Router } from '@angular/router';
import { Location, NgIf, NgFor } from '@angular/common';
import { ERROR_CODE, ERROR_MAP } from '@core/constants/error-code';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { take } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'pbb-error-page',
  templateUrl: './error-page.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./error-page.component.scss'],
  standalone: true,
  imports: [NgIf, MatDialogModule, MatButtonModule, MatIconModule, NgFor, TranslateModule],
})
export class ErrorPageComponent implements OnInit {
  error: PbbError = ERROR_MAP[ERROR_CODE.SYSTEM];
  retryUrl?: string;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private location: Location,
    @Optional() @Inject(MAT_DIALOG_DATA) private data: { errorCode: ERROR_CODE },
    @Optional() public dialogRef: MatDialogRef<ErrorPageComponent>
  ) {}

  ngOnInit(): void {
    const errorCode = this.activatedRoute.snapshot.params['errorCode'] || this.data?.errorCode;
    this.error = ERROR_MAP[errorCode?.toUpperCase() || ERROR_CODE.SYSTEM];
    this.activatedRoute.queryParams.pipe(take(1)).subscribe((params) => {
      if (params['retryUrl']) {
        this.retryUrl = decodeURIComponent(params['retryUrl']);
      }
    });
  }

  onButtonClick(errorButton: ErrorButton) {
    if (errorButton.url) {
      this.router.navigate([errorButton.url]);
    } else if (errorButton.callBack) {
      errorButton.callBack();
    } else if (this.retryUrl) {
      this.router.navigateByUrl(this.retryUrl);
    } else if (!this.retryUrl && !errorButton.url && !errorButton.callBack && !this.dialogRef) {
      this.location.back();
    }

    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }
}
